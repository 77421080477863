import axios from 'axios';

const defaultBaseUrl = process.env.REACT_APP_API_URL;

export async function DBRequest({
  baseUrl = defaultBaseUrl,
  path,
  data = {},
  method = 'get',
  onResponse = () => {},
  onError = () => {},
  onLoading = () => {},
}) {
  onLoading(true);
  const token = localStorage.getItem('jwtToken');
  await axios({
    method: method,
    url: baseUrl + path,
    data: data,
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      onResponse(response.data);
      onLoading(false);
    })
    .catch((error) => {
      console.error('Error fetching data:', error);
      onError(error);
      onLoading(false);
    });
}

export function DBUploadDataInChunks({
  baseUrl = defaultBaseUrl,
  path,
  file,
  chunkSize,
  onProgressChange = () => {},
  onFinished = () => {},
  onResponse,
  onFeedback = () => {},
  debug = false,
  onError = () => {},
  maxRetries = 3, // Add a maximum retry count
}) {
  const uploadFileInChunks = async (file, chunkSize, uploadUrl) => {
    const token = localStorage.getItem('jwtToken');
    const headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    };

    try {
      const totalChunks = Math.ceil(file.size / chunkSize);

      const sendChunk = async (start, end, index, retries = 0) => {
        const formData = new FormData();
        const blobSlice = file.slice(start, end);
        formData.append('file', blobSlice, file.name);
        formData.append('dzchunkbyteoffset', start);
        formData.append('dztotalchunkcount', totalChunks);
        formData.append('dzchunksize', chunkSize);
        formData.append('dztotalfilesize', file.size);
        formData.append('dzchunkindex', index);

        try {
          await axios
            .post(uploadUrl, formData, { headers })
            .then((response) => {
              if (totalChunks - 1 === index) onResponse(response.data);
            });
        } catch (error) {
          if (retries < maxRetries) {
            console.warn(
              `Chunk ${index} failed, retrying... (${retries + 1}/${maxRetries})`
            );
            await sendChunk(start, end, index, retries + 1); // Retry the chunk
          } else {
            console.error(`Chunk ${index} failed after ${maxRetries} retries.`);
            throw error;
          }
        }
      };

      for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSize;
        const end =
          (i + 1) * chunkSize > file.size ? file.size : (i + 1) * chunkSize; // Adjust end for the last chunk
        await sendChunk(start, end, i); // Handle retries within sendChunk
        const percent = Math.round(((i + 1) / (totalChunks - 1)) * 100) + ' %';
        onProgressChange(
          totalChunks - 1 === i + 1 ? 'Processing data' : percent
        ); // Update progress
      }

      onFinished(true);
    } catch (error) {
      onError(error);
      console.error('Error during chunk upload:', error);
    }
  };

  uploadFileInChunks(file, chunkSize, baseUrl + path);
}
