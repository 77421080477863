import Keycloak from 'keycloak-js';

const keycloak = new Keycloak({
  url: process.env.REACT_APP_KEYCLOAK_URL,
  realm: process.env.REACT_APP_KEYCLOAK_REALM,
  clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
  clientSecret: process.env.REACT_APP_KEYCLOAK_CLIENT_SECRET,
});

export const initKeycloak = async (onInit, onError) => {
  try {
    const authenticated = await keycloak.init({
      onLoad: 'login-required',
    });
    onInit(authenticated);
    console.log(
      `User is ${authenticated ? 'authenticated' : 'not authenticated'}`
    );
    if (authenticated) {
      const token = keycloak.token;
      localStorage.setItem('jwtToken', token);
    }
  } catch (error) {
    onError(error);
  }
};
export default keycloak;
